import React, { InputHTMLAttributes, MutableRefObject, useEffect, useRef } from "react";
import { obsoleteWarning } from "@telia/b2x-obsolete-component";
import { TeliaAssistiveText } from "@telia/b2x-telia-assistive-text";
import classnames from "classnames";

import styles from "./b2x-telia-radio-button.module.scss";

const generateRandom = () => Math.floor(Math.random() * 1000000);

const getUniqueId = (identifier?: string) => {
  const idPart = identifier ? `${identifier}-` : "";
  return `${idPart}${generateRandom()}-${generateRandom()}`;
};

type RadioButtonProps = InputHTMLAttributes<HTMLInputElement> & {
  additional?: string;
  additionalId?: string;
  checked?: boolean | undefined;
  dataTestid?: string;
  htmlForm?: string;
  label?: string;
};

export const RadioButton = ({
  additional,
  additionalId = getUniqueId("additional"),
  checked,
  children,
  className: classNameProp,
  dataTestid,
  disabled = false,
  htmlForm,
  label,
  name,
  value,
  id = getUniqueId(),
  ...inputProps
}: RadioButtonProps) => {
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const classname = "b2x-telia-radio-button";
  const showAdditional = Boolean(additional);

  useEffect(() => {
    obsoleteWarning(classname, "inputs-radiobuttongroup--docs");
  }, []);

  useEffect(() => {
    if (htmlForm) {
      inputRef.current.setAttribute("form", htmlForm);
    }
  }, [htmlForm]);

  return (
    <div
      className={classnames(classNameProp, styles[classname], {
        [styles[`${classname}__disabled`]]: disabled,
      })}
    >
      <label htmlFor={id} className={classnames(styles[`${classname}__main`], "b2x-telia-label")}>
        <input
          {...inputProps}
          id={id}
          data-testid={dataTestid}
          className={styles[`${classname}__input`]}
          type="radio"
          name={name}
          ref={inputRef}
          value={value}
          disabled={disabled}
          checked={checked}
          form={htmlForm}
        />

        <div className={styles[`${classname}__fake-radio-button`]}>
          <div className={styles[`${classname}__fake-dot`]} />
        </div>

        {!inputProps["aria-labelledby"] && (
          <span className={styles[`${classname}__label`]}>{label || children}</span>
        )}
      </label>

      {showAdditional && (
        <TeliaAssistiveText
          className={classnames(styles[`${classname}__additional`], {
            [styles[`${classname}__disabled`]]: disabled,
          })}
          id={additionalId}
        >
          {additional}
        </TeliaAssistiveText>
      )}
    </div>
  );
};
